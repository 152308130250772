.DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 7rem;
  padding: 1rem;
  position: relative;
}

.DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b .linked-logo {
  display: flex;
  justify-content: center;
}

.DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b .logo {
  width: 10rem;
}

.DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: "Bebas Neue";
  font-size: 1rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: #eb0a8d;
}

.DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* Show/hide navigation */

@media (min-width: 900px) {
  .DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b {
    padding: 3em 1.5em 1.5em;
    height: 9rem;
  }

  .DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b .linked-logo {
    flex-grow: 1;
  }
  .DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b .logo {
  }

  .DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b .navigation {
    flex-grow: 4;
  }

  .DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b .Navigation {
    display: flex;
    font-size: 1.25rem;
  }

  .DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b .Navigation {
    font-size: 1.5rem;
  }
  .DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b .logo {
    width: 15.6rem;
  }
}
.MarketingBody4072b52554af4750A2a535d5874f3ac4 {
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .MarketingBody4072b52554af4750A2a535d5874f3ac4 {
  }
}
.SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b {
  min-height: 19rem;
  position: relative;
  color: #030C22;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: clip;
}

.SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b .background-color {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spla/images/spla-skyline.jpeg);
  background-size: cover;
  background-position: center;
  height: 155%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.5;
}

.SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b .header-top-wrapper {
  position: relative;
}

.SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b .header-top {
  z-index: 1;
  position: relative;
}

@media (min-height: 900px) {
  .SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b {
    min-height: 24rem;
  }
  .SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b .background-image {
    height: 100%;
  }
}
.MarketingCardsAf6d5bba4c1a400396a5D0403b626ced {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 7rem;
}

.MarketingCardsAf6d5bba4c1a400396a5D0403b626ced .MarketingCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}

@media (min-width: 768px) {
  .MarketingCardsAf6d5bba4c1a400396a5D0403b626ced .MarketingCard {
    gap: 6rem;
  }
}

.MarketingCardsAf6d5bba4c1a400396a5D0403b626ced .MarketingCard .ImageOverlay {
}

@media (min-width: 768px) {
  .MarketingCardsAf6d5bba4c1a400396a5D0403b626ced .CardTextContent {
    width: 25rem;
  }
}

.MarketingCardsAf6d5bba4c1a400396a5D0403b626ced .CardHeading {
  color: #500064;
  font-family: Bebas Neue;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .MarketingCardsAf6d5bba4c1a400396a5D0403b626ced .CardHeading {
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5;
  }
}

.MarketingCardsAf6d5bba4c1a400396a5D0403b626ced .CardText {
  font-family: Montserrat;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
}

.MarketingCardsAf6d5bba4c1a400396a5D0403b626ced .ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

@media (min-width: 768px) {
  .MarketingCardsAf6d5bba4c1a400396a5D0403b626ced .ButtonWrapper {
    justify-content: flex-start;
  }
}

.MarketingCardsAf6d5bba4c1a400396a5D0403b626ced .Button {
  background-color: #780050;
  color: white;
  font-family: 'Bebas Neue'; 
  font-weight: 700; 
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}
.PageQuote4db482018d694349B85a1212e0e56dc1 {
  display: flex;
  flex-wrap: wrap;
}
.ShortHeader00e74f76B0314fbb852b23e9a5ca18eb {
  background-color: white;
  color: #030C22;
  position: relative;
}

.ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spla/images/spla-skyline.jpeg);
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
}

.ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  padding: 0 1.5rem;
  position: relative;
}

.ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top .linked-logo {
  display: flex;
  justify-content: center;
}

.ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top .logo {
  width: 8rem;
}

.ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: 'Bebas Neue';
  font-size: 1.25rem;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: #eb0a8d;
}

.ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Montserrat;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

/* Larger screens */

@media (min-width: 900px) {
  .ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top .linked-logo {
    flex-grow: 1;
  }
  .ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top .logo {
    width: 9rem;
  }

  .ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top .navigation {
    flex-grow: 4;
  }

  .ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top .Navigation {
    display: flex;
  }

  .ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top {
    padding: 0 1.75rem;
    height: 5rem;
  }

  .ShortHeader00e74f76B0314fbb852b23e9a5ca18eb .header-top .logo {
    width: 10rem;
  }
}
.DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc {
  background-color: #7e2e84;
  color: #ef798a;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 3rem 2rem;
  font-family: Montserrat;
}

.DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc > * {
  text-align: center;
}

.DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc .Navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc .Navigation .Link:hover {
  text-decoration-line: underline;
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc > .logo-wrapper {
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc .LogoWrapper {
  font-size: 90%;
}

.DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc .LogoWrapper {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc .LogoWrapper:hover {
    --tw-text-opacity: 1;
    color: rgb(0 194 152 / var(--tw-text-opacity));
}

.DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc .Logo {
  height: 40px;
  width: 150px;
  fill: currentColor;
}

.DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc .GroupCopyright {
  font-size: 90%;
}

.DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc .GroupFlowTag .PoweredBy {
  font-size: 90%;
}
.WelcomeBackPopUp {
  padding: 2rem;
  position: relative;
  background-color: white;
  box-shadow: 5px 5px 5px rgb(50 50 50 / 20%);
}

.WelcomeBackPopUp .CloseButton {
  color: black;
}

.WelcomeBackPopUp .BackgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spla/images/spla-skyline.jpeg);
  opacity: 0.5;
  background-position: center;
  background-size: cover;
}

.WelcomeBackPopUp .Body {
  position: relative;
}

.WelcomeBackPopUp .Button {
  background-color: #780050;
  color: white;
  font-family: 'Bebas Neue';
  font-size: 120%;
  letter-spacing: 0.05rem;
  padding: 0.5rem 2rem;
  text-align: center;
}

.SideCar {
  background-color: #f9f5e3;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: 'Bebas Neue';
  font-size: 1.25rem;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  color: #eb0a8d;
  display: block;
}

.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

.HomePageEf6a465743ea4a4b825e82bb3e33a2fb .PageQuote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.HomePageEf6a465743ea4a4b825e82bb3e33a2fb .PageQuote .image {
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.HomePageEf6a465743ea4a4b825e82bb3e33a2fb .PageQuote .TextContent {
  color: #500064;
  font-family: "Bebas Neue";
  font-size: 3rem;
  letter-spacing: 0.1rem;
  line-height: 4rem;
  position: relative;
  padding: 3rem 2rem;
  text-align: center;
  text-shadow: white 0px 0px 4px, white 0px 0px 4px, white 0px 0px 4px, white 0px 0px 4px;
}

.HomePageEf6a465743ea4a4b825e82bb3e33a2fb .PageQuote .Title {
  font-family: "Bebas Neue";
  font-size: 350%;
  line-height: 100%;
}

.HomePageEf6a465743ea4a4b825e82bb3e33a2fb .PageQuote .Text {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 125%;
}

@media (min-width: 900px) {
  .HomePageEf6a465743ea4a4b825e82bb3e33a2fb .PageQuote {
      min-height: 37rem;
  }
  .HomePageEf6a465743ea4a4b825e82bb3e33a2fb .PageQuote .TextContent {
    font-size: 4.25rem;
    padding: 4rem;
  }
}

@media (min-width: 1200px) {
  .HomePageEf6a465743ea4a4b825e82bb3e33a2fb .PageQuote .Title {
  }
}
.MarketingCardsB8f8cae766754d75925693532adfaddc {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 7rem;
}

.MarketingCardsB8f8cae766754d75925693532adfaddc .MarketingCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}

.MarketingCardsB8f8cae766754d75925693532adfaddc .CardTextContent {
  padding-left: 1rem;
  padding-right: 1rem;
}

.MarketingCardsB8f8cae766754d75925693532adfaddc .CardHeading {
  color: #eb0a8d;
  font-family: 'Bebas Neue';
  font-size: 4rem;
  letter-spacing: 0.1rem;
  text-align: center;
  line-height: 1;
}

.MarketingCardsB8f8cae766754d75925693532adfaddc .CardText {
  font-family: Montserrat;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
}

.MarketingCardsB8f8cae766754d75925693532adfaddc .ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.MarketingCardsB8f8cae766754d75925693532adfaddc .Button {
  background-color: #eb0a8d;
  color: white;
  font-family: Montserrat; 
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  padding: 0.75rem 3rem;
}

@media (min-width: 768px) {
  .MarketingCardsB8f8cae766754d75925693532adfaddc .MarketingCard {
    gap: 6rem;
  }

  .MarketingCardsB8f8cae766754d75925693532adfaddc .CardTextContent {
    width: 25rem;
  }

  .MarketingCardsB8f8cae766754d75925693532adfaddc .CardHeading {
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5;
  }

  .MarketingCardsB8f8cae766754d75925693532adfaddc .ButtonWrapper {
    justify-content: flex-start;
  }
}
.SecondaryMarketingHero7bdcdb8a8b3745ad9719Ffff926f91d0 {
  color: #030C22;
  flex-grow: 1;
  padding-left: 1.5rem;
}

.SecondaryMarketingHero7bdcdb8a8b3745ad9719Ffff926f91d0 .title {
  color: black;
  font-family: 'Bebas Neue';
  font-size: 4rem;
  font-weight: 500;
  line-height: inherit;
}

@media (min-width: 640px) {
  .SecondaryMarketingHero7bdcdb8a8b3745ad9719Ffff926f91d0 {
    padding-left: 5rem;
  }

  .SecondaryMarketingHero7bdcdb8a8b3745ad9719Ffff926f91d0 .title {
    font-size: 400%;
  }
}

.SecondaryMarketingHero7bdcdb8a8b3745ad9719Ffff926f91d0 .button > a {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 110%;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.5rem 2rem;
  background-color: #ed2e38;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.SecondaryMarketingHero7bdcdb8a8b3745ad9719Ffff926f91d0 .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.SecondaryNav94b293ccB5a446a8A146B44c8ca28797 {
  margin: 1rem 0;
}
.HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a {
  color: #030C22;
  min-height: 42rem;
  padding: 1.5rem;
  position: relative;
  text-align: left;
}

@media (min-width: 768px) {
  .HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a .title {
  color: black;
  font-family: 'Bebas Neue';
  font-size: 4rem;
  line-height: 4rem;
  margin-bottom: 1.2rem;
  letter-spacing: 0.15rem;
  text-align: center;
}

.HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a .body {
  letter-spacing: 1.8px;
  line-height: 1.2em;
  margin-bottom: 1rem;
  max-width: 45rem;
  font-size: 1.25rem;
  font-weight: 300;
  font-family: Montserrat;
}

.HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a .button {
  display: flex;
  justify-content: center;
}

.HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a .button > a {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Montserrat;
  font-weight: 300;
  font-size: 150%;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.75rem 3rem;
  background-color: #eb0a8d;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 900px) {
  .HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a .title {
    text-align: left;
  }

  .HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a .button {
    display: block;
    justify-content: start;
  }
}
.SecondaryPage88e97e86403c48d6911354ad91970a52 .text-content {
  padding: 0 1rem;
}
.HomeHeaderDa36af7f51c0441aBff2Bc70ced6e2be {
  position: relative;
  height: 40rem;
  color: #030C22;
  overflow: clip;
}

.HomeHeaderDa36af7f51c0441aBff2Bc70ced6e2be .background-color {
  background-color: white;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.HomeHeaderDa36af7f51c0441aBff2Bc70ced6e2be .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spla/images/spla-skyline.jpeg);
  background-size: cover;
  background-position: 50%!important;
  height: 143%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.5;
  z-index: -1;
}

.HomeHeaderDa36af7f51c0441aBff2Bc70ced6e2be .header-top-wrapper {
  position: relative;
  height: 6rem;
}

.HomeHeaderDa36af7f51c0441aBff2Bc70ced6e2be .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 900px) {
  .HomeHeaderDa36af7f51c0441aBff2Bc70ced6e2be {
    height: auto;
  }
  .HomeHeaderDa36af7f51c0441aBff2Bc70ced6e2be .header-top-wrapper {
    height: 9.25rem;
  }
  .HomeHeaderDa36af7f51c0441aBff2Bc70ced6e2be .background-image {
    background-position: center;
    height: 100%;
  }
}